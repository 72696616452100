import React from 'react';
import MainNavBarMobile from './mobile/MainNavBar';
import MainContentMobile from "./mobile/MainContent";


import "./css/LandingPage.css";
import FooterMobile from "./mobile/Footer";

class LandingPageMobile extends React.Component {

    componentDidMount() {
        const navbar = document.getElementsByClassName("container-fluid")[0];
        const pageintro = document.getElementById("mainh");
        if (navbar && pageintro){
            const navbarheight = navbar.offsetHeight;
            pageintro.style.marginTop=`${navbarheight + 100}px`
        }
    }
  render() {
    return (


      <div className="mobile" >

              <MainNavBarMobile />
      <MainContentMobile />

          <FooterMobile />



      </div>
    );
  }
}

export default LandingPageMobile;