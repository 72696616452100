import React from 'react';
import logo from '../assets/logo.png';
import 'bootstrap';
import '../css/MainNavBar.css'
import vk from '../assets/vk.png'
class MainNavBarDesktop extends React.Component {




  render() {
    return (
      <div>
        <nav>
          <div className="container-fluid" style={{ width: "100vw", margin: "auto" }}>
            <img src={logo} alt={'logo'} style={{ width: "12vw" }} />
            <div className="dropdown">


                <div className="links">
                    <a href="#spezialisation">Специализация</a><br></br>
                    <a href="#stories">Истории пациентов</a><br></br>
                    <a href="#education">Обучение</a><br></br>
                    <a href="#publication">Публикации</a><br></br>

                    <a href="#footer">Контакты</a><br></br>
                    
                </div>

            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default MainNavBarDesktop;



