import React from 'react';
import '../css/FloatingLinks.css';
import vk from '../assets/vk.png'
import tg from '../assets/telegram.png'
import wh from '../assets/whatsapp.png'


const FloatingLinks = () => {
  return (
    <div className="floating-links">
      <a href="https://vk.com/garmanovatatiana?from=search" target="_blank" rel="noopener noreferrer">
        <img src={vk} alt="Link 1" />
      </a>
      <a href="https://t.me/surgerymgu_bot" target="_blank" rel="noopener noreferrer">
        <img src={tg} alt="Link 2" />
      </a>
      <a href="https://api.whatsapp.com/send?phone=79773429249" target="_blank" rel="noopener noreferrer">
        <img src={wh} alt="Link 3" />
      </a>
    </div>
  );
};

export default FloatingLinks;
