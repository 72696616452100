import React, { useEffect } from 'react';

const PdWidgetColumn = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://prodoctorov.ru/static/js/widget_column.js?v5';
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="pd_widget_column" className="pd_widget_column" data-doctor="290939">
      <a target="_blank" rel="noopener noreferrer" href="https://prodoctorov.ru/">
        <img className="pd_logo" src="https://prodoctorov.ru/static/_v1/pd/logos/logo-pd-widget.png" alt="ProDoctorov Logo" />
      </a>
      <a target="_blank" rel="noopener noreferrer" className="pd_doctor_name" href="https://prodoctorov.ru/moskva/vrach/290939-garmanova/">
        Гарманова Татьяна Николаевна
      </a>
      <div id="pd_widget_column_content_d290939" className="pd_widget_column_content"></div>
      <a target="_blank" rel="noopener noreferrer" href="https://prodoctorov.ru/moskva/vrach/290939-garmanova/#otzivi">читать отзывы</a>
    </div>
  );
};

export default PdWidgetColumn;
