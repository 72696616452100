import React from 'react';
import  './../css/Footer.css'; // Убедитесь, что создали соответствующий CSS файл
import logo from './../assets/logo.png'
const Footer = () => {
  return (
      <footer id="footer">
          <table style={{width: "100%"}}>
              <tr>
                  <td>
                      <img src={logo} alt='Логотип' style={{width: "10vw"}}/>
                  </td>
                  <td style={{textAlign: "left"}}>
                      <div className="text">
                          Условия обработки персональных данных
                      </div>
                      <div className="copyRight">
                          © 2023 "Гарманова Татьяна Николаевна" Все права защищены
                      </div>
                  </td>
                  <td style={{textAlign: "right"}}>

                      <div className="phones">
                          <div>+7 (967) 136-77-06</div>
                          <div>+7 (977) 342-92-49</div>
                      </div>
                  </td>
              </tr>

          </table>


      </footer>
  );
};

export default Footer;
