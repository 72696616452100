import React from "react";
import HeroSection from './../mobile/HeroSection';
import StoriesSection from './../mobile/StoriesSection';
import Addings from './../mobile/Addings';
import FeedbackForm from './../mobile/FormFooter';

import '../css/MainNavBar.css'
import Links from "../mobile/FloatingLinks";
class MainContent extends React.Component{
    render(){
        return (
            <div style={{width:"100vw"}}  >

                <HeroSection />

                <StoriesSection />
                <Addings />

                <FeedbackForm />
                <Links />





            </div>

        )
    }
}


export default MainContent;