import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserView, MobileView} from 'react-device-detect';
import LandingPageDesktop from './LandingPageDesktop';
import LandingPageMobile from "./LandingPageMobile";
import reportWebVitals from './reportWebVitals';


import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <body>
    <React.StrictMode>

<BrowserView>
    <LandingPageDesktop />
</BrowserView>
<MobileView>
    <LandingPageMobile />
</MobileView>




  </React.StrictMode>
    </body>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

