
import React, { useEffect, useRef, useState } from 'react';
import "../css/HeroSection.css"
import "../fonts/Gilroy-Bold.ttf"
import PdWidgetColumn from './PdWidgetColumn';
function HeroSection() {


    return (
      <section id="textHero"   >



          <div style={{position: 'relative'}} >


              <div className='textHero' >
                  <p style={{fontSize: "4vh"}}>
                      <b>
                          ГАРМАНОВА <br></br>ТАТЬЯНА<br></br>
                          НИКОЛАЕВНА
                      </b>
                  </p>

                  <p style={{fontSize: "4vh"}}>
                      кандидат медицинских наук
                  </p>
                  <p style={{fontSize: "4vh", color: 'rgb(53, 40, 110)'}}>
                      колопроктолог, онколог, хирург
                  </p>
                  <div className='hero-btns-d'>
                      <a href="#Ff" id='cons'>Записаться на консультацию</a>
                      <a href="mailto:info@uniclinic.pro" id="ask">Задать вопрос</a>
                  </div>
                  <p style={{fontSize: "2vh", maxWidth: "40vw", color: "rgb(53, 40, 110)", marginTop: "1vh"}}>Доцент
                      кафедры хирургии факультета фундаментальной медицины МГУ имени М. В. Ломоносова</p>
                  <PdWidgetColumn />
              </div>
          </div>

          <div id="spezialisation">
              <h1>
                  Специализация
              </h1>
              <ul>
                  <li>
            Колопроктология
          </li>
            <li>
Хирургия
          </li>
            <li>
Онкология
          </li>
            <li>
Малоинвазиваня хирургия
          </li>
            <li>
Преподавательская деятельнсоть
          </li>
            <li>
Консервативное и хирургическое лечение заболеваний перианальной области и анального канала: геморроя, анальных трещин, аноректальных свищей, полипов

          </li>
            <li>Эстетическая хирургия перианальной области


          </li>
            <li>
Диагностика и лечение сочетанных дисфункциональных нарушений мочеполовой системы и толстой кишки

          </li>
            <li>
Диагностика злокачественных новообразований толстой и прямой кишки

          </li>
            <li>
Минимально инвазивное хирургическое лечение злокачественных опухолей толстой кишки с использованием высокоэнергетических технологий и лапароскопических доступов

          </li>
            <li>
Реконструктивно-восстановительное лечение пациентов с кишечными стомами.
после ранее перенесенных оперативных вмешательств

          </li>
            <li>
Диагностика и лечение неспецифических воспалительных заболеваний кишечника (язвенный колит, болезнь Крона)

          </li>
            <li>
Профессиональное образование студентов и врачей

          </li>
            <li>
Общая врачебная практика, прием амбулаторных колопроктологических пациентов

          </li>
            <li>
Диагностика и лечение тазовой дисценции, ректоцеле и синдрома обструктивной дефекации
          </li>
        </ul>
        </div>
      </section>
    );

}

export default HeroSection;
