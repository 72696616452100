import React, { useState } from "react";
import "../css/FormFooter.css";
import AppointmentPhoto from "../assets/FeedbackForm.png";

const FormFooter = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    agreement: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://garmanovasurgery.ru//submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Форма успешно отправлена');
        // Дополнительные действия после успешной отправки, например, очистка формы
        setFormData({
          name: '',
          email: '',
          phone: '',
          agreement: false,
        });
      } else {
        console.error('Ошибка при отправке формы');
      }
    } catch (error) {
      console.error('Ошибка отправки:', error);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  }

  return (
    <div className="FormFooter" id="Ff">
      <div className="FormTextFooter">
        <form onSubmit={handleSubmit} className="AppointmentDetails" onKeyPress={handleKeyPress}>
          <b className="titleFormFooter">Записаться на прием</b><br />
          <b className="textFormFooter">
            В ближайшее время с Вами свяжется администратор для согласования времени и формата консультации
          </b><br />
          <input
            name="name"
            placeholder="Ваше имя"
            className="inputFormFooter"
            value={formData.name}
            onChange={handleChange}
          /><br />
          <input
            name="email"
            placeholder="E-mail"
            className="inputFormFooter"
            value={formData.email}
            onChange={handleChange}
          /><br />
          <input
            name="phone"
            placeholder="Номер телефона"
            className="inputFormFooter"
            value={formData.phone}
            onChange={handleChange}
          /><br />
          <label className="textFormFooter">
            <input
              type="checkbox"
              name="agreement"
              className="checkboxFormInput"
              checked={formData.agreement}
              onChange={handleChange}
            />
            <span className="customCheckboxFormInput" />
            Примите пользовательское соглашение
          </label><br />
          <button type="submit" className="buttonFormInputMobile">Записаться на прием</button>
        </form>
      </div>
      <div className="PhotoContainer">
        <img
          src={AppointmentPhoto}
          alt="Фотография приема"
          className="AppointmentPhoto"
        />
      </div>
    </div>
  );
};

export default FormFooter;
