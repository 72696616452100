import React from 'react';
import "../css/StoriesSection.css";
import story1 from './../assets/attempt1.jpg';
import story2 from './../assets/Drs-19_resized.jpg';
import story3 from './../assets/Drs-21_resized.jpg';
import story4 from './../assets/IMG_0389_resized.jpg';
import {Card, CardBody, CardImg, CardTitle, Col, Container, Row} from "shards-react";

class StoriesSection extends React.Component{
  render() {
    return (
      <section id="stories">
          <h1>Истории пациентов</h1>
        <Container className="dr-example-container">

            <Row>
            <Col>
      <Card style={{  marginBottom: "5vh"  }}>

      <CardImg src={story1} />
      <CardBody>
        <CardTitle>Опухоль сигмовидной кишки</CardTitle>
        <p>Еще одна история пациента нашего отделения. Снова рак... </p>
      </CardBody>

    </Card>
            </Col>
            <Col>


                                    <Card style={{  marginBottom: "5vh"  }}>

      <CardImg src={story2} />
      <CardBody>
        <CardTitle>Симультанная хирургия</CardTitle>
        <p>Сегодня история такой пациентки, которая перенесла такое вмешательство</p>
      </CardBody>

    </Card>
            </Col>
              </Row><Row>




<Col>
                                            <Card style={{  marginBottom: "5vh"  }}>

      <CardImg src={story3}  />
      <CardBody>
        <CardTitle>Рак прямой кишки</CardTitle>
        <p>"Никогда, никогда, никогда не сдавайся". - Уинстон Черчилль. </p>
      </CardBody>

    </Card>

</Col>

            <Col>
                                                    <Card style={{  marginBottom: "5vh"  }}>

      <CardImg src={story4}  />
      <CardBody>
        <CardTitle>Опухоль брюшной полости</CardTitle>
        <p>История пациента которому вместе с врачами удалось добиться прогресса в лечении опухоли</p>
      </CardBody>

    </Card>
            </Col>
              </Row>




              </Container>

      </section>
    )
  }
}

export default StoriesSection;