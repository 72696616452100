import React from "react";
import HeroSection from './HeroSection';
import StoriesSection from './StoriesSection';
import Addings from './Addings';
import FeedbackForm from './FormFooter';
import ReviewsSection from "./ReviewSection";
import '../css/MainNavBar.css'
import Links from "./FloatingLinks"

class MainContent extends React.Component{
    render(){
        return (
            <div style={{width:"100vw"}}  >

                <HeroSection />

                <StoriesSection />
                <Addings />
                <FeedbackForm />
                <Links />




            </div>

        )
    }
}


export default MainContent;