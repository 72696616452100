import React from 'react';

import "../css/Addings.css"



class Addings extends React.Component {
  render() {
    return (

        <div >
            <div id="education">
                <h1>
                    Образование
                </h1>
                <div className="courses">
                    <p className="info-education">
                        <b>Московский государственный университет имени Ломоносова МГУ</b><br/>
                        2007<br/>
                        Лечебное дело с отличием
                    </p>
                    <p className="info-education">
                        <b>НИИ урологии и интервенционной радиологии им. Н.А. Лопаткина</b><br/>
                        2010<br/>
                        Урология<br/>
                        Ординатура
                    </p>
                    <p className="info-education">
                        <b>Первый Московский государственный медицинский университет им. И.М. Сеченова</b><br/>
                        2015<br/>
                        Хирургия<br/>
                        Интернатура
                    </p>
                    <p className="info-education">
                        <b>Российская Медицинская академия Непрерывного Профессионального Образования (РМАНПО)</b><br/>
                        2015<br/>
                        Урология<br/>
                        Повышение квалификации
                    </p>
                    <p className="info-education">
                        <b>Первый Московский государственный медицинский университет им. И.М. Сеченова</b><br/>
                        2017<br/>
                        Колопроктология<br/>
                        Циклы переподготовки
                    </p>
                    <p className="info-education">
                        <b>Московский научно-исследовательский онкологический институт им. П.А. Герцена (МНИОИ)</b><br/>
                        2022<br/>
                        Онкология<br/>
                        Циклы переподготовки
                    </p>
                </div>
            </div>
            <div id="publication">
                <h1>
                    Научная деятельность
                </h1>
                <div className="courses">
                    <p style={{fontSize: "4vh"}}>
                        Книги
                    </p>
                    <p>
                        <ul></ul>
                        <li>
                            П.В. Царьков, Федеров Д.Н., Тулина И.А., Гарманова Т.Н.<br></br>
                            Рекомендации по стандартам и протоколам исследования при злокачественных новообразованиях.
                            Протокол патогистологического исследования при колоректальном раке.
                            2018
                            <div className="isd">место издания Издательский дом РОКХ. Москва, 1000 с.</div>
                        </li>
                        <li>
                            Царьков П.В., Деринов А.А., Тулина И.А., Гарманова Т.Н.
                            Практические рекомендации по периоперационному ведению пациентов с колоректальным раком.
                            2018
                            <div className="isd">место издания Издательский дом РОКХ. Москва</div>
                        </li>
                        <li>
                            Царьков П.В., Тулина И.А., Гарманова Т.Н.<br></br>
                            Геморрой.
                            2018
                            <div className="isd">место издания Издательский дом РОКХ. Москва</div>
                        </li>
                        <li>
                            Царьков П.В., Тулина И.А., Гарманова Т.Н.<br></br>
                            Рекомендации Японского общества по лечению колоректального рака
                            2019
                            <div className="isd">место издания Издательство РОКХ, Москва, ISBN 978-5-6041205-6-9, 54
                                с.</div>
                        </li>
                        <li>
                            Царьков П.В., Тулина И.А., Гарманова Т.Н.<br></br>
                            Клинические рекомендации Американского общества колоректальных хирургов по лечению семейного
                            полипоза
                            2019
                            <div className="isd">место издания Издательство РОКХ, Москва Россия, ISBN
                                978-5-6041205-5-2</div>
                        </li>
                        <li>
                            Кубышкин В.А., Агапов М.А., Ковалев А.И., Кочатков А.В., Алиханов Р.Б., Есаков Ю.С., Луммер
                            К.Б., Кульбак В.А., Гарманова Т.Н., Богданова Н.П.<br></br>
                            Тестовые задания для контроля знаний по курсу частной хирургии
                            2020
                            <div className="isd">издательство ООО "МАКС Пресс" (Москва) , ISBN 978-5-317-06486-0, 160
                                с.</div>
                        </li>
                        <li>
                            Хрянин А.А., Соколовская А.В., Маркарьян Д.Р., Гарманова Т.Н.<br></br>
                            Заболевания кожи промежности и перианальной области.
                            2022
                            <div className="isd"> издания геотар москва, ISBN 978-5-9704-6917-0, 192 с.,</div>
                        </li>
                    </p>
                </div>


                <div className="articles">
                    <p style={{fontSize: "4vh"}}>
                        Публикации
                    </p>
                    <p>
                        <ul></ul>
                        <li>
                            Markaryan D.R., Lukyanov A.M., Garmanova T.N., Agapov M.A., Kubyshkin V.A. <br/>
                            Postpartum fecal incontinence. State of the problem
                            2022
                            2018
                            <div className="isd">в журнале Khirurgiya. Zhurnal im. N.I. Pirogova, № 6, с. 127 DOI</div>
                        </li>
                        <li>
                            Ekaterina Kazachenko 1., Tatiana Garmanova 1., Alexander Derinov 2., Daniil Markaryan 1., <br/>
                            Hanjoo Lee 3., Sabrina Magbulova 4., Petr Tsarkov 2.
                            Preemptive analgesia for hemorrhoidectomy: study protocol for a prospective, randomized,
                            double-blind trial
                            2022
                            <div className="isd">в журнале Trials, издательство BioMed Central (London), том 23, с.
                                536</div>
                        </li>
                        <li>
                            Novikova Anastasia, Pavlov Rostislav, Danilin Rostislav, Nepomnyaschaya Svetlana, Gadaev
                            Shamil, Markaryan Daniil, Garmanova Tatyana, Agapov Mikhail, Kakotkin Viktor, Goncharov
                            Artem<br/>
                            Prevalence and Associated factors of Low Anterior Resection Syndrome after Sphincter-saving
                            resections for rectal cancer in Russia: cross-sectional, multi-center Study
                            2022
                            <div className="isd">в журнале European Journal of Surgical Oncology, издательство W. B.
                                Saunders Co., Ltd. (United Kingdom), том 48, № 2, с. e106-e107 DOI</div>
                        </li>
                        <li>
                            Agapov M., Garmanova T., Kazachenko E., Markaryan D., Galliamov E., Kakotkin V., Kubyshkin
                            V., Semina E., Kamalov A.<br/>
                            The impact of surgical delay on resectability of colorectal cancer: An international
                            prospective cohort study COVIDSurg Collaborative 2022
                            <div className="isd">в журнале Colorectal Disease, издательство Blackwell Publishing Inc.
                                (United Kingdom), том 24, № 6, с. 708-726 DOI</div>
                        </li>
                        <li>
                            Garmanova T.N., Krivonosova D.A., Agapov M.A., Semina E.V., Galliamov E.A., Markaryan D.R.,
                            Kakotkin V.V.<br/>
                            The role of the urokinase system in colorectal carcinogenesis
                            2019
                            <div className="isd">в журнале Сибирское медицинское обозрение, № 2, с. 20-29 DOI</div>
                        </li>

                        <li>
                            Agapov M., Galliamov E., Kakotkin V., Kubyshkin V., Semina E., Garmanova T., Kazachenko E.,
                            Markaryan D.<br/>
                            Effect of COVID-19 pandemic lockdowns on planned cancer surgery for 15 tumour types in 61
                            countries: an international, prospective, cohort study
                            <div className="isd">в журнале Lancet Oncology, издательство The Lancet Publishing Group
                                (United Kingdom), том 22, № 11, с. 1507-1517 DOI</div>
                        </li>


                    </p>
                </div>


            </div>
        </div>


    );
  }
}

export default Addings;
//We specialize in custom web application development for businesses. Our experienced team can design, build, and test websites or apps that meet your unique needs. We have expertise in a wide range of technologies, including React, Python, C++, C#, PHP, JavaScript, Node.js, HTML/CSS, AJAX, and REST APIs. Contact us for the best web development solutions.
// We provide comprehensive mobile app development services for various platforms, including React Native, Android, and iOS. Our experienced team focuses on crafting engaging, performant, and secure apps with modern UI design tailored to your specific business needs. Contact us today for high-quality and customized mobile app solutions.
// We specialize in creating cutting-edge web and mobile apps using React, JavaScript, Python, Node.js, and other modern technologies. With expertise in responsive user interfaces, seamless API integrations, and scalable custom back-end solutions, our experienced team can take your project to the next level. Contact us today to turn your vision into a reality.